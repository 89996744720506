<template>
    <div class="newsMasterContainer">
        <el-row class="newsRow">
            <el-col class="newsContainer" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <div class="newsHeader">{{ header }}</div>
                <div class="newsCreatorAndDate">Oprettet d. {{ createdDate }}</div>
                <div class="newsBody">{{ content }}</div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
  name: 'NewsComponent',
  props: {
      header: String,
      createdDate: String,
      content: String
  }
}
</script>

<style scoped>

.newsMasterContainer {
    max-width: 800px;
    width: 100%;
}

.newsRow {
    padding-left: 20px;
    padding-right: 20px;
}

.newsContainer {
    float: left;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.newsHeader {
    color: #9433ff;
    font-size: 24px;
    width: 100%;
    margin-top: 10px;
}

h3 {
  height: 15px !important;
}

.newsCreatorAndDate {
    font-size: 12px;
}

@media only screen and (max-width: 768px) {
    .newsRow {
        padding: 0 !important;
    }
}

</style>
