<template>
    <div class="newsMasterContainer">
        <el-row class="newsRow">
            <el-col class="newsContainer" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <div class="newsHeader">Ansvarlig skærmannoncering</div>
                <div class="newsCreatorAndDate">Sidst opdateret d. 31/08-2022</div>
                <div class="newsBody">
                    <p>
                        Hos Reklameskærm.dk tager vi ansvar for annoncering og sørger for at annoncering eksekveres anvarligt. 
                    </p>
                    <h3>Dyreliv</h3>
                    <p>
                        Vi tager forbehold for lysforurening og repræsenterer ikke skærme, som er henvendt mod skovområder og andet dyreliv og som alene er udslagsgivende for lysforurening.
                    </p>

                    <h3>Hvid farve</h3>
                    <p>
                        En klar hvid farve lyser kraftigere end andre farver. Hvis en annonce består af mere end 50% hvid farve, ændrer vi i farvens nuance således annonceringen ikke kan forekomme blændende.
                    </p>

                    <h3>Stødende budskaber</h3>
                    <p>
                        Hos Reklameskærm.dk omfavner vi alle med et godt budskab. Vi har dog en poltik som lyder, at vi ikke tillader grafik, som indeholder trafikskilte, budskaber med seksuelt indhold eller budskaber som vurderes meget stødende af os eller ejere af skærmene.
                    </p>
                    
                    <h3>Kommunal politik og planlov</h3>
                    <p>
                        Hos Reklameskærm.dk respekterer vi landsdækkende og kommunalpolitiske beslutninger og vi følger deres individuelle anvisninger ift. skærmenes drift.
                    </p>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
  components: {  },
  name: 'ResponsibleAds',
  props: {
      Header: Number,
      createdBy: String,
      createdDate: String,
      content: String,
  }
}
</script>

<style scoped>

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    h3 {
        padding-bottom: 25px;
    }
}

h3 {
  height: 15px !important;
}


.newsMasterContainer {
    max-width: 800px;
    width: 100%;
}

.newsRow {
    padding-left: 20px;
    padding-right: 20px;
}

.newsContainer {
    float: left;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.newsHeader {
    color: #9433ff;
    font-size: 24px;
    width: 100%;
    margin-top: 10px;
}

.newsCreatorAndDate {
    font-size: 12px;
}

a, a:active {
  color: #9433ff !important;
}

</style>
