<template>
    <div class="newsMasterContainer">
        <el-row class="newsRow">
            <el-col class="newsContainer" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <div class="newsHeader">Kontakt os</div>
                <div class="newsCreatorAndDate">Sidst opdateret d. 11/12-2021</div>
                <div class="newsBody">Har du et spørgsmål eller vil du gerne i kontakt med os, kan du herunder finde vores kontaktoplysninger.</div>
                <div class="newsBody">
                    <ul>
                        <li>Email: kontakt@reklameskaerm.dk</li>
                        <li>Telefon: +45 93 60 90 01</li>
                        <li>Adresse: Visionsvej 15 2.1, 9000 Aalborg</li>
                    </ul>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
  name: 'Contact',
  props: {
      Header: Number,
      createdBy: String,
      createdDate: String,
      content: String,
  }
}
</script>

<style scoped>
h3 {
  height: 15px !important;
}

.newsMasterContainer {
    max-width: 800px;
    width: 100%;
}

.newsRow {
    padding-left: 20px;
    padding-right: 20px;
}

.newsContainer {
    float: left;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.newsHeader {
    color: #9433ff;
    font-size: 24px;
    width: 100%;
    margin-top: 10px;
}

.newsCreatorAndDate {
    font-size: 12px;
}

</style>
