<template>
  <meta-data 
        :Title="'Hjælp og info'" 
        :Description="'Privatlivspolitik, handelsbetingelser og meget mere.'"
        :Auhtor="'Reklameskærm.dk'"
        />
  <div class="top-spacer"></div>
  <div class="help-page">
          <h2>Hjælp og info</h2>
          <el-tabs v-model="activeName" :tab-position="tabPosition">
            <el-tab-pane class="tab-pane" label="Nyheder" name="Nyheder" v-for="news in newsList" :key="news.header">
              <news-component :header="news.header" :createdDate="news.createdDate" :content="news.content"></news-component>
            </el-tab-pane>
            <el-tab-pane label="For skærmudbyder" name="ForSkaermudbyder">
              <for-providers-component></for-providers-component>
            </el-tab-pane>
            <el-tab-pane label="Om os" name="OmOs">
              <about-us-component></about-us-component>
            </el-tab-pane>
            <el-tab-pane label="Udregning af eksponering" name="UdregningAfEksponering">
              <calculation-info></calculation-info>
            </el-tab-pane>
            <el-tab-pane label="Handelsbetingelser" name="Handelsbetingelser">
              <terms-component></terms-component>
            </el-tab-pane>
            <el-tab-pane label="Privatlivspolitik" name="Privatlivspolitik">
              <privacy-policy-component></privacy-policy-component>
            </el-tab-pane>
            <el-tab-pane label="Ansvarlig skærmannoncering" name="Ansvarlig_skaermannoncering">
              <responsible-ads></responsible-ads>
            </el-tab-pane>
            <el-tab-pane label="Kontakt os" name="KontaktOs">
              <contact></contact>
            </el-tab-pane>
          </el-tabs>
  </div>

</template>

<script lang="ts">
import NewsComponent from '../components/about/NewsComponent.vue'
import ForProvidersComponent from '../components/about/ForProvidersComponent.vue'
import CalculationInfo from '../components/about/CaluclationInfo.vue'
import AboutUsComponent from '../components/about/AboutUsComponent.vue'
import PrivacyPolicyComponent from '../components/about/PrivacyPolicyComponent.vue'
import TermsComponent from '../components/about/TermsComponent.vue'
import Contact from '../components/about/Contact.vue'
import ResponsibleAds from '../components/about/ResponsibleAds.vue'
import MetaData from '../components/shared/MetaData.vue'

export default {
  name: 'HelpPage',
  components: { 
    NewsComponent,
    ForProvidersComponent,
    AboutUsComponent,
    PrivacyPolicyComponent,
    TermsComponent,
    Contact,
    CalculationInfo,
    ResponsibleAds,
    MetaData
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      tabPosition: 'left',
      activeName: 'Nyheder',
     
      newsList: [
        {
          header: 'Nyheder',
          createdDate: '01/01-2022',
          content: `Velkommen til Reklameskærm.dk. Efter måneders hårdt arbejde lancerer vi i dag booking platformen.
          vil løbende blive opdateret med ny funktioner og skulle I opleve fejl og mangler, må i meget gerne meddele dette til os på kontakt@reklameskaerm.dk.`
        }
      ],
      };
    },
    methods: {
      onResize() {
        this.windowWidth = window.innerWidth;
      },
      handleResize(width) {
        if(width <= 766) {
          this.tabPosition = 'top';
        }
        else {
          this.tabPosition = 'left';
        }
      }
    },
    mounted() {
      var goToSection = this.$route.query.goTo;
      if(goToSection !== undefined)
      {
        this.activeName = goToSection;
      }

      this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
      });
      this.handleResize(window.innerWidth);      
    },
    beforeUnmount() { 
        window.removeEventListener('resize', this.onResize); 
    },
    watch: {
      windowWidth(newWidth) {
        this.handleResize(newWidth);
      }
    }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media only screen and (min-width: 768px) {
    /* For mobile phones: */
    .help-page {
      border-top: 1px solid rgb(204 204 204);
    }
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .help-page {
      width: 95% !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    .help-page h2 {
      padding-left: 20px;
    }

    .tab-pane {
      padding-left: 20px;
      padding-right: 10px;
    }

    .top-spacer {
      height: 50px !important;
    }
}

.top-spacer {
  width: 100%;
  height: 80px;
}

.help-page {
  line-height: 25px;
  width: 100%;
  max-width: 1488px;
  margin: 0 auto;
  padding-left: 20px;
}

.treeview {
    background-color: transparent !important;
}


</style>
