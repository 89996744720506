<template>
    <div class="newsMasterContainer">
        <el-row class="newsRow">
            <el-col class="newsContainer" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <div class="newsHeader">Privatlivspolitik hos Reklameskaerm.dk</div>
                <div class="newsCreatorAndDate">Sidst opdateret d. 15/01-2022</div>
                <div class="newsBody">
                    <p>
                        Vi indhenter kun persondata i de tilfælde, hvor dette skulle være relevant for os, og vi vil kun indhente person- /virksomhedsdata, hvor det er relevant for din aktivitet på Reklameskaerm.dk.
                    </p>
                    <p>
                        Ved indsamling, behandling og anvendelse af dine persondata overholder vi altid alle relevante og gældende lovbestemmelser.
                    </p>
                    <p>
                        Vi vil kun opbevare dine persondata, så længe vi endten er pålagt af juridrisk forpligtelse hertil, eller så længe det er relevant for den hensigt, hvormed de blev indsamlet.
                    </p>
                    <h3>Oplysninger vi indsamler</h3>
                    <p>
                        Hvis du ønsker at købe og modtage et produkt eller ydelse fra os, har vi brug for at indsamle visse person- /virksomhedsdata, for at kunne gennemføre handlen og for at kunne tilbyde dig vores services.
                        Vi bruger bl.a. cookies til at holde styr på indholdet i din indkøbskurv, mend du bruger vores platform.
                    </p>
                    <p>
                        Vi kan indhendte oplysninger som f.eks. navn, e-mailadresse, adresse, postnummer, by, virksomhedsnavn, CVR, telefonnummer og betalingsoplysninger.
                    </p>
                    <p>
                        Reklameskaerm.dk indsamler og behnalder dine persondata, når du foretager dig følgende:
                    </p>
                    <ul>
                        <li>Besøger vores hjemmeside</li>
                        <li>Gennemføre et køb af vores udbudte skærme</li>
                        <li>Opretter en konto</li>
                        <li>Tilmelder dig vores nyhedsbrev</li>
                    </ul>
                    <h3>Den dataansvarlige</h3>
                    <p>
                        Den dataansvarlige for indsamling, behandling og anvende af dine personoplysninger på www.reklameskaerm.dk er Showpromote ApS, Visionsvej 15 2.1, 9000 Aalborg.
                    </p>
                    <h3>Behandlingsgrundlag og formål</h3>
                    <p>
                        Dine almindelige kontaktoplysninger som navn og adresse indhenter vi for at kunne levere det produkt eller ydelse, som du har købt hos os. Din e-mailadresse indhenter vi for at kunne sende dig en kviterring efter et køb.
                    </p>
                    <p>
                        Når du betaler for dit produkt eller din ydelse indsamler vi dit navn, dine kortdata og IP-adresse. 
                        De oplysninger, der indsamles i forbindelse med betalingstransaktion, anvendes og gemmes kun til betalingsafvikling og opfyldelse af den indgåede aftale.
                        Ved gennemførelse af betalinger, vil nogle af dine data blive vidergivet til Onpay.io, inklusiv information der er nødvendig for at gennemføre eller understøtte betalingen, såsom det totale købsbeløb og faktureringsinformation.
                    </p>
                    <p>
                        Hvis du under registreringsprocessen, eller på vores hjemmeside, har givet os dit sammentykke hertil, bruger vi din e-mailadresse til udsendelse af nyhedsbrev.
                        Du kan til enhver tid til- og afmelde dig nyhedsbrevet. Alle nyhedsbreve indeholder også et link til afmelding. Hvis behandling af persondata er baseret på dit samtykke, har du til enhver tid ret til at tilbagekalde samtykket.
                    </p>
                    <p>
                        Dine oplysninger vil som udgangspunkt blive slettet efter 5 år, efter seneste aktivitet på din konto.
                    </p>
                    <h3>Andre modtagere af personoplysninger</h3>
                    <p>
                        Vi sælger ikke dine persondata til tredjemand, og vi overføre ikke dine data til tredjelande.
                    </p>
                    <p>
                        Vi har vores hjemmeside hos Time4VPS og betalingssystem gennem Onpay.io, som fungere som vores databehandler. Alle persondata som du oplyse på vores hjemmeside, vil blive opbevaret i Time4VPS's datacenter.
                    </p>
                    <h3>Dine rettigheder</h3>
                    <p>
                        Som den registrerede har du en række rettigheder, som vi til enhver tid skal sikre opfyldelse af.
                        Du har ret til at anmode os om følgende:
                    </p>
                    <ul>
                        <li>At få adgang til og få ændret dine persondata</li>
                        <li>At få slettet persondata</li>
                    </ul>
                    <p>
                        Du har deruover ret til at protestere over behandlinen af dine personlige data, og du har ret til at indgive klage til en databeskyttelsesmyndighed.
                    </p>
                    <p>
                        Ønsker du ikke længere, at vi skal behandle dine personoplysninger, eller at vi begænser behandlingen af dine personoplysninge, kan du også sende os en anmodning herom på kontakt@reklameskaerm.dk
                    </p>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
  name: 'PrivacyPolicyComponent',
  props: {
      Header: Number,
      createdBy: String,
      createdDate: String,
      content: String,
  }
}
</script>

<style scoped>

.newsMasterContainer {
    max-width: 800px;
    width: 100%;
}

.newsRow {
    padding-left: 20px;
    padding-right: 20px;
}

.newsContainer {
    float: left;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.newsHeader {
    color: #9433ff;
    font-size: 24px;
    width: 100%;
    margin-top: 10px;
}

h3 {
  height: 15px !important;
}

.newsCreatorAndDate {
    font-size: 12px;
}

</style>
