<template>
    <div class="newsMasterContainer">
        <el-row class="newsRow">
            <el-col class="newsContainer" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <div class="newsHeader">Information for skærmudbyder</div>
                <div class="newsCreatorAndDate">Sidst opdateret d. 11/12-2021</div>
                <div class="newsBody">
                    <h3>Tjen penge, mens I sparer tid</h3>
                
                    <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" style="text-align: center; padding-right: 20px;" class="why-partner-bullets">
                        <el-row>
                        <el-col :span="24">
                            <el-row>
                            <el-col :span="24" style="text-align: left; font-size: 32px; height: 45px;"><i class="el-icon-goods" /></el-col>
                            <el-col :span="24" style="text-align: left;"><b>Salg</b></el-col>
                            <el-col :span="24" style="text-align: left; font-size: 14px;">Vi er jeres supplerende salgskanal og supplere jeres nuværende salgsindsats med yderligere annoncører.</el-col>
                            </el-row>
                        </el-col>
                        <el-col :span="24">
                            <el-row>
                            <el-col :span="24" style="text-align: left; font-size: 32px; height: 45px;"><i class="el-icon-setting" /></el-col>
                            <el-col :span="24" style="text-align: left;"><b>Administration</b></el-col>
                            <el-col :span="24" style="text-align: left; font-size: 14px;">Vi udskifter annoncerne på skærmen.</el-col>
                            </el-row>
                        </el-col>
                        </el-row>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" style="text-align: center; padding-bottom: 40px;" class="why-partner-bullets">
                        <el-row>
                        <el-col :span="24">
                            <el-row>
                            <el-col :span="24" style="text-align: left; font-size: 32px; height: 45px;"><i class="el-icon-connection" /></el-col>
                            <el-col :span="24" style="text-align: left;"><b>Markedsføring</b></el-col>
                            <el-col :span="24" style="text-align: left; font-size: 14px;">Vi sørger for at SEO optimere og markedsføre jeres reklameskærm.</el-col>
                            </el-row>
                        </el-col>
                        <el-col :span="24">
                            <el-row>
                            <el-col :span="24" style="text-align: left; font-size: 32px; height: 45px;"><i class="el-icon-wallet" /></el-col>
                            <el-col :span="24" style="text-align: left;"><b>Udbetaling</b></el-col>
                            <el-col :span="24" style="text-align: left; font-size: 14px;">Vi fremsender en udbetalingsspecifikation hvert kvartal, hvorefter jeres annoncesalg vil blive udbetalt</el-col>
                            </el-row>
                        </el-col>
                        </el-row>
                    </el-col>

                    <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/25mNMskaNVY" title="til skærmejere - Reklameskærm.dk" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    
                    <h3>Hvordan bliver vi partner?</h3>
                    <el-col :span="24" style="margin-top: 20px;">
                        <p>
                            Vi vil meget gerne høre om jer, jeres skærm og jeres ønsker. Hvis i kan se et match mellem jer og os, vil et samarbejde med Reklameskærm.dk let kunne istandsættes.
                            Et samarbejde som endda er helt risikofrit og hvor vi står for hele opsætningen.
                        </p>
                        <p>
                            Send os en mail på <a href="mailto:kontakt@reklameskaerm.dk">kontakt@reklameskaerm.dk</a> eller giv Martin et kald på 93 60 90 01.
                        </p>
                    </el-col>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
  name: 'ForProvidersComponent',
  props: {
      Header: Number,
      createdBy: String,
      createdDate: String,
      content: String,
  }
}
</script>

<style scoped>

h3 {
  height: 15px !important;
}

.newsMasterContainer {
    max-width: 800px;
    width: 100%;
}

.newsRow {
    padding-left: 20px;
    padding-right: 20px;
}

.newsContainer {
    float: left;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.newsHeader {
    color: #9433ff;
    font-size: 24px;
    width: 100%;
    margin-top: 10px;
}

.newsCreatorAndDate {
    font-size: 12px;
}

.why-us-bullets {
  font-size: 22px;
}

.why-us-bullets i {
  font-size: 24px;
  color: #9433ff !important;
}

.why-partner-bullets i {
  color: #9433ff !important;
}

a, a:active {
  color: #9433ff !important;
}



</style>
