<template>
    <div class="newsMasterContainer">
        <el-row class="newsRow">
            <el-col class="newsContainer" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <div class="newsHeader">Om Reklameskærm.dk</div>
                <div class="newsCreatorAndDate">Sidst opdateret d. 11/12-2021</div>
                <div class="newsBody">
                    Reklameskærm.dk samler diverse virksomheders reklameskærme i det danske land på ét sted og gør det let for jer som virksomhed at målrette jeres markedsføring geografisk.
                    <br><br>

                    <el-row style="padding-bottom: 40px;">
                        <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
                            <div class="workerCard">
                                <div class="worker-img martin-img" />
                                <div class="Name">Martin Tiedemann</div>
                                <div class="Role">Salg og markedsføring</div>
                            </div>
                        </el-col>
                        <el-col :xs="12" :sm="8" :md="8" :lg="8" :xl="8">
                            <div class="workerCard">
                                <div class="worker-img steffen-img" />
                                <div class="Name">Steffen H. Nielsen</div>
                                <div class="Role">Web ansvarlig</div>
                            </div>
                        </el-col>
                    </el-row>

                    Teamet bag Reklameskærm.dk består af Steffen og Martin, som er bosat i Aalborg. Vi ønsker at vække potentialet for outdoor annoncering i Danmark. Vi mener, at det skal være let og effektivt og lige så synes vores samarbejdspartnere.

                    <h3>Vi er ikke kun to</h3>
                    Selvom det interne team består af to personer, består platformen af en del flere. Reklameskærm.dk samarbejder med diverse virksomheder i Danmark, som har deres skærm repræsenteret på vores platform.

                </div><br>
                
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
  name: 'AboutUsComponent',
  props: {
      Header: Number,
      createdBy: String,
      createdDate: String,
      content: String,
  }
}
</script>

<style scoped>

.newsMasterContainer {
    max-width: 800px;
    width: 100%;
}

h3 {
  height: 15px !important;
}

.newsRow {
    padding-left: 20px;
    padding-right: 20px;
}

.newsContainer {
    float: left;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.newsHeader {
    color: #9433ff;
    font-size: 24px;
    width: 100%;
    margin-top: 10px;
}

.newsCreatorAndDate {
    font-size: 12px;
}

.workerCard {
    width: 100%;
    max-width: 180px;
    float: left;
    border-radius: 6px;
    text-align: center;
}

.martin-img {
  background-image: url('~@/assets/images/martin_3.webp');
  background-size:     cover;
  background-repeat:   no-repeat;
  background-position: 0px -20px; 
  width:100%;
  height: 200px;
}

.steffen-img {
  background-image: url('~@/assets/images/steffen_3.webp');
  background-size:     cover;
  background-repeat:   no-repeat;
  background-position: 0px -20px; 
  width:100%;
  height: 200px;
}

.workerCard img {
    padding: 20px 5px 0px 5px;
    width: 100%;
}

.workerCard .Name {
    float: left;
    width: 100%;
    font-size: 15px;
    font-weight: 600;
    height: 20px;
}

.workerCard .Role {
    float: left;
    width: 100%;
    padding-bottom: 10px;
    font-size: 13px;
    color: rgb(129, 129, 129);
    font-weight: 600;
}

</style>
