<template>
    <div class="newsMasterContainer">
        <el-row class="newsRow">
            <el-col class="newsContainer" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <div class="newsHeader">Udregning af eksponering</div>
                <div class="newsCreatorAndDate">Sidst opdateret d. 11/12-2021</div>
                <div class="newsBody">
                    <h3>Hvordan beregnes antallet af eksponeringer?</h3>
                    <p>
                        Antallet af eksponeringer angiver hvor mange personer, som mindst vil passere skærmen på en varighed af én måned. Når antallet af eksponeringer udregnes tager vi højde for adskillige variabler; antallet af trafikanter, timer hvor skærmen er tændt, mængden af personer pr. køretøj og det antal annoncer, som afspilles på den pågældende skærm.
                    </p>

                    <h3>Hvorfor beregnes det sådan?</h3>
                    <p>
                        Vi medtager alle variabler i vores udregninger, da det giver det mest retvisende billede af én måneds annoncering. Herved sikrer vi, at enhver annoncør kan tage en beslutning på et retvisende grundlag.
                    </p>

                    <h3>Vores data</h3>
                    <p>
                        Vi indhenter data fra kommunale, regionale og landsdækkende trafiktællinger. DTU’s transportvaneundersøgelse viser, at et gennemsnitligt køretøj består af 1,31 personer, hvilket resulterer i, at trafiktællingerne multipliceres med 1,31.
                    </p>
                    <p>
                        <a target="_blank" href="https://www.cta.man.dtu.dk/-/media/Centre/Modelcenter/modeller-og-publikationer/Faktaark/2014-Faktaark_personer_per_koeretoej.ashx?la=da&hash=BF3E918DABBEBE2EFD4D914C6B6294D093EFC45B">
                        Klik her for at downloade kilde (PDF fil)
                        </a>
                    </p>
                    <p>
                        Enkelte lokationer har ikke kommunale trafiktællinger tilgængelige, og her har vi hos <a href="https://reklameskaerm.dk">Reklameskærm.dk</a> selv stået for trafiktællingerne. Tællingerne er udført ved at finde det gennemsnitlige antal af trafikanter, som passerer skærmen. Tællingerne er foretaget ved at finde det gennemsnitlige antal mellem den time på ugen, hvor der er mest trafik i området og den time på ugen hvor der er mindst trafik i området.
                    </p>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
  components: {  },
  name: 'CalculationInfo',
  props: {
      Header: Number,
      createdBy: String,
      createdDate: String,
      content: String,
  }
}
</script>

<style scoped>

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    h3 {
        padding-bottom: 25px;
    }
}

h3 {
  height: 15px !important;
}


.newsMasterContainer {
    max-width: 800px;
    width: 100%;
}

.newsRow {
    padding-left: 20px;
    padding-right: 20px;
}

.newsContainer {
    float: left;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.newsHeader {
    color: #9433ff;
    font-size: 24px;
    width: 100%;
    margin-top: 10px;
}

.newsCreatorAndDate {
    font-size: 12px;
}

a, a:active {
  color: #9433ff !important;
}

</style>
