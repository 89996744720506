<template>
    <div class="newsMasterContainer">
        <el-row class="newsRow">
            <el-col class="newsContainer" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <div class="newsHeader">Handelsbetingelser hos Reklameskærm.dk</div>
                <div class="newsCreatorAndDate">Sidst opdateret d. 11/12-2021</div>
                <div class="newsBody">
                    <p>
                       Følgende vilkår gælder for alle ydelser og produkter, der bliver solgt og leveret gennem Showpromote ApS 
                        og andre hjemmesider, der administreres af Showpromote ApS. Handelsbetingelserne gælder for enhver opgave, 
                        Showpromote ApS påtager sig. Din adgang til og brug af hjemmesiden udgør samtykke til at være bundet af 
                        disse betingelser, som etablerer et kontraktforhold mellem dig og Showpromote ApS. Hvis du ikke giver 
                        samtykke til vilkårene, må du ikke tage adgang til eller bruge hjemmesiden.
                    </p>
                    <p>
                        Showpromote ApS kan fra en tid til anden ændre vilkårene. Ændringerne i vilkårene vil træde i kraft, 
                        efter Showpromote ApS slår sådanne opdaterede vilkår op på rette sted. Din fortsatte adgang til eller 
                        brug af hjemmeside eller køb af vores produkter efter sådanne opslag udgør samtykke til at være bundet 
                        af de ændrede vilkår.
                    </p>
                    <h3>Generelle oplysninger</h3>
                    <p>
                        Showpromote ApS & Reklameskærm.dk
                        <br />
                        CVR: 42752495
                        <br />
                        Visionsvej 15 2.1,
                        <br />
                        9000 Aalborg
                        <br />
                        kontakt@reklameskaerm.dk
                        <br />
                        +45 93609001
                    </p>
                    <p>
                        Showpromote ApS er formidler og leverendør af annoncepladser og annonceringsserviceydelser leveret gennem digitale skærme.
                    </p>
                    <h3>
                        Priser og betaling
                    </h3>
                    <p>
                        Den gældende pris er den, som på dagen for bestillin er angivet på den pågældende ydelse. Alle priser er ekslusive moms og angiver i DKK.
                    </p>
                    <p>
                        Showpromote ApS kan frit ændre de oplyste priser uden forudgående varsel. 
                        Er der sket en trykfejl og køber burde vide eller ved, at der er tale om en trykfejl, 
                        kan køber ikke få den trykfejlede pris. Showpromote ApS kan til hver en tid annullere en ordre,
                        der er sket ved en trykfejl på prisen.
                        Ved bestilling kan du se den endelig pris, hvilket er inklusive moms og afgifter. 
                    </p>
                    <p>
                        Hos Showpromote ApS kan der betales med Visa- /Dankort og Mastercard. Ved betaling med betalingskort trækkes beløbet når ydelsen købes.
                    </p>
                    <h3>Køb af annoncepladser</h3>
                    <p>
                        Ved køb af en annonceplads vil køber kunne vælge de specifikke skærme køber ønsker at få fremvist sine annoncer på.
                        Herefter vælger køber hvilke uger annoncen skal føres på de pågældende skærme. 
                        Køber opnår først ret til den pågældende annonceplads, efter betalingen er gennemført. 
                        Gennemføres betalingen ikke, kan køber ikke kræve adgang til den pågældende annonceplads. 
                    </p>
                    <p>
                        Køber bedes herefter uploade sit grafiske materiale til Showpromote ApS's platform. 
                        Såfremt køber ikke har et udarbejdet grafisk materiale vil Showpromote ApS mod betaling 
                        kunne være køber behjælpelig herved. 
                    </p>
                    <p>
                        Køber er selv ansvarlig for upload og ændring af grafisk materiale før og under annonceringsperioden.
                    </p>
                    <p>
                        Showpromote ApS har til enhver tid ret til at fjerne én eller flere allerede igangværende annonce(r), 
                        hvis ejer/udbyder af skærmen(e) finder annoncen etisk stødende eller på anden måde kan have negativ 
                        effekt på sine omgivelser. Såfremt ejer/udbyder af skærmen(e) ikke ønsker at have annoncen vist, 
                        har Showpromote ApS ret til at fjerne annoncen. Ved fjernelse af igangværende annonce vil Showpromote ApS 
                        kontakte køber via e-mail i forbindelse med udbedring af hændelsen. 
                    </p>
                    <h3>Fortrolighed</h3>
                    <p>
                        Hos Showpromote ApS mener vi, at tillid og fortrolighed er afgørende for et godt samarbejde. 
                        Alle oplysninger modtaget fra køber eller om køber vil blive behandlet fortroligt, 
                        og alle medarbejdere er underlagt tavshedspligt. 
                    </p>
                    <h3>Ansvar</h3>
                    <p>
                        Showpromote ApS er ikke ansvarlige for nedbrud eller midlertidige afbrydelser på vores hjemmeside, 
                        nedbrud i strømforsyning eller internetforbindelse, hærværk på systemet (både fysisk såvel som computervirus og hacking), 
                        misbrug af personoplysninger eller andre forhold og omstændigheder, 
                        der er uden for Showpromote ApS kontrol. Det kan ikke garanteres, 
                        at du kan anvende downloadede filer på alle computere, og ved brugen af filerne vil det altid formodes, 
                        at du er i besiddelse af en software, som kan åbne/læse den relevante fil.
                    </p>
                    <h3>Produktansvar</h3>
                    <p>
                        For sådanne skader, som umiddelbart er omfattet af lov nr. 481 af 7. juni 1989 om produktansvar, gælder lovens bestemmelser.
                    </p>
                    <p>
                        For så vidt angår produktansvar, der ikke er omfattet af ovennævnte lovs bestemmelser, gælder følgende begrænsninger: 
                    </p>
                    <ul>
                        <li>
                            Showpromote ApS er kun ansvarlig for personskade, hvis det bevises, at skaden skyldes fejl eller forsømmelse 
                            begået af Showpromote ApS eller andre, som Showpromote ApS har ansvaret for.
                        </li>
                        <li>
                            Showpromote ApS er ansvarlig for skade på fast ejendom og løsøre på samme betingelser som for personskade. 
                        </li>
                    </ul>
                    <p>
                        Showpromote ApS og kunden er gensidigt forpligtet til at lade sig sagsøge ved den domstol eller voldgiftsret, 
                        som behandler erstatningskrav, der er rejst mod en af dem på grundlag af en skade, som påstås forårsaget af materiellet.
                    </p>
                    <h3>Levering af ydelsen</h3>
                    <p>
                        Levering af en bestilt annonceplads vil ske på det tidspunkt som køber har købt sig ind på. 
                        Såfremt køber foretager bestillingen online, 
                        vil annonceringen som udgangspunkt først kunne påbegyndes 72 timer efter tidspunktet for bestillingen. 
                    </p>
                    <p>
                        Hvis leverancen af ydelsen forsinkes, vil vi Showpromote ApS underrette kunden herom pr. e-mail.
                    </p>
                    <p>
                        Showpromote ApS kan til enhver tid, efter eget skøn, annullere en bestilling, forinden denne igangsættes. 
                    </p>
                    <h3>Fejl og mangler</h3>
                    <p>
                        I tilfælde af fejl og mangler ved den leverede ydelse, herunder grundet tekniske fejl eller lign.,
                        der vedvarer i over 48 timer, da vil køber blive krediteret et beløb tilsvarende til det tidsrum 
                        hvori købers annonce ikke har været aktiv på den pågældende skærm. 
                    </p>
                    <h3>Tilbagebetaling af købsbeløb</h3>
                    <p>
                        Såfremt køber i medfør af ovenstående punkt om “Fejl og Mangler” skal tilbagekrediteres en del af sit købsbeløb, 
                        vil dette senest ske 30 dage fra tidspunktet hvorpå fejlen eller manglen identificeres.
                    </p>
                    <p>
                        I følgende tilfælde vil kunden aldrig kunne kræve tilbagebetaling af enten dele eller hele købsbeløbet:
                    </p>
                    <ul>
                        <li>
                            Efter bestilling af en annonceplads.
                        </li>
                        <li>
                            Ved køb af flere ugers annonceplads, kan køber ikke afslutte og kræve tilbagebetaling for fremtidige ugers annoncering.
                        </li>
                        <li>
                            Ved manglende fremsendelse af grafisk materiale til brug for annonceringen. 
                        </li>
                    </ul>
                    <h3>Privatlivspolitik</h3>
                    <p>
                        For at du kan indgå en aftale med os og handle på reklameskaerm.dk har vi brug for følgende oplysninger om dig:
                        Virksomheds navn, CVR-nummer, mail, telefonnummer, betalingsoplysninger og navn på kontaktperson hos virksomheden. 
                    </p>
                    <p>
                        Behandlingen sker efter reglerne i vores Privatlivspolitik.
                    </p>
                    <h3>Klageadgang</h3>
                    <p>
                        Hvis du vil klage over dit køb, skal du rette henvendelse til os på kontakt@reklameskaerm.dk
                    </p>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
  name: 'TermsComponent',
  props: {
      Header: Number,
      createdBy: String,
      createdDate: String,
      content: String,
  }
}
</script>

<style scoped>

.newsMasterContainer {
    max-width: 800px;
    width: 100%;
}

.newsRow {
    padding-left: 20px;
    padding-right: 20px;
}

.newsContainer {
    float: left;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.newsHeader {
    color: #9433ff;
    font-size: 24px;
    width: 100%;
    margin-top: 10px;
}

h3 {
  height: 15px !important;
}

.newsCreatorAndDate {
    font-size: 12px;
}

</style>
